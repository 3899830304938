import React from 'react';
import styled from 'styled-components';
import Config from '../../../data.json';

function Settings(props) {
  return (
    <Wrapper>
      <h1>首頁</h1>
      {Config.stage === 'stg' || Config.stage === 'dev' ? (
        <React.Fragment>
          <div
            style={{
              padding: 10,
              border: 'solid 1px #ddd',
              display: 'inline-block',
              marginRight: 15,
            }}>
            <img
              src="/images/qrcode-stg-test-store-table.png"
              style={{width: 220, objectFit: 'contain'}}
            />
            <div style={{textAlign: 'center'}}>總部-訓練教室 1桌</div>
          </div>
          <div
            style={{
              padding: 10,
              border: 'solid 1px #ddd',
              display: 'inline-block',
              marginRight: 15,
            }}>
            <img
              src="/images/qrcode-stg-test-store-takeaway.png"
              style={{width: 220, objectFit: 'contain'}}
            />
            <div style={{textAlign: 'center'}}>總部-訓練教室 外帶</div>
          </div>
          <div
            style={{
              padding: 10,
              border: 'solid 1px #ddd',
              display: 'inline-block',
              marginRight: 15,
            }}>
            <img
              src="/images/qrcode-revtel-store-table.png"
              style={{width: 220, objectFit: 'contain'}}
            />
            <div style={{textAlign: 'center'}}>總部-忻旅 1桌</div>
          </div>
          <div
            style={{
              padding: 10,
              border: 'solid 1px #ddd',
              display: 'inline-block',
            }}>
            <img
              src="/images/qrcode-revtel-store-takeaway.png"
              style={{width: 220, objectFit: 'contain'}}
            />
            <div style={{textAlign: 'center'}}>總部-忻旅 外帶</div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div
            style={{
              padding: 10,
              border: 'solid 1px #ddd',
              display: 'inline-block',
              marginRight: 15,
            }}>
            <img
              src="/images/qrcode-prod-test-store-table.png"
              style={{width: 220, objectFit: 'contain'}}
            />
            <div style={{textAlign: 'center'}}>總部-訓練教室 1桌</div>
          </div>
          <div
            style={{
              padding: 10,
              border: 'solid 1px #ddd',
              display: 'inline-block',
            }}>
            <img
              src="/images/qrcode-prod-test-store-takeaway.png"
              style={{width: 220, objectFit: 'contain'}}
            />
            <div style={{textAlign: 'center'}}>總部-訓練教室 外帶</div>
          </div>
        </React.Fragment>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  & > h1 {
    font-size: 32px;
  }
`;

export default Settings;
